import { template as template_b1545c7b83e641baa95d1553224e3ebf } from "@ember/template-compiler";
const WelcomeHeader = template_b1545c7b83e641baa95d1553224e3ebf(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
