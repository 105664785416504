import { template as template_76fb7bcc3ba348c4a73205956974da3d } from "@ember/template-compiler";
const FKLabel = template_76fb7bcc3ba348c4a73205956974da3d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
