import { template as template_26a4793d17274e86bae3699b3811dc5a } from "@ember/template-compiler";
import { concat, hash } from "@ember/helper";
import { LinkTo } from "@ember/routing";
import i18n from "discourse-common/helpers/i18n";
const GroupActivityFilter = template_26a4793d17274e86bae3699b3811dc5a(`
  <li>
    <LinkTo
      @route={{concat "group.activity." @filter}}
      @query={{hash category_id=@categoryId}}
    >
      {{i18n (concat "groups." @filter)}}
    </LinkTo>
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default GroupActivityFilter;
